import { MedicationRequest } from '@hl7fhir';
import { CodeableConceptPipe } from '@hl7fhir/data-types';
import { MedicationRequestViewModel } from '@hl7fhir/resource-types';
import * as r3 from 'fhir/r3';
import { getCodingsDetails, joinValues } from '../section';

export class MedicationViewModel {
  get medication(): string | undefined {
    const medicationRequest: MedicationRequest | undefined = this.source.resource;

    if (medicationRequest?.medicationCodeableConcept) {
      return new CodeableConceptPipe().transform(medicationRequest.medicationCodeableConcept);
    } else if (medicationRequest?.medicationReference) {
      return (
        medicationRequest.medicationReference.display ?? medicationRequest.medicationReference?.reference?.split('/')[1]
      );
    }

    return undefined;
  }

  get codings(): string | undefined {
    const resource: r3.MedicationRequest = this.source.resource as r3.MedicationRequest;
    return getCodingsDetails(resource.category?.coding);
  }

  get dosageInstructions(): string | undefined {
    const resource: r3.MedicationRequest = this.source.resource as r3.MedicationRequest;
    const texts: (string | undefined)[] | undefined = resource.dosageInstruction?.map(
      (dosage: r3.Dosage) => dosage.text,
    );

    return joinValues(texts, ', ');
  }

  get notes(): string[] | undefined {
    const resource: r3.MedicationRequest = this.source.resource as r3.MedicationRequest;
    const notes: string[] | undefined = resource.note?.map((annotation: r3.Annotation) => annotation.text);

    return notes;
  }

  get toString(): string | undefined {
    return joinValues([this.medication, this.codings, this.dosageInstructions], ' - ');
  }

  constructor(readonly source: MedicationRequestViewModel) {}
}
