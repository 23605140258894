import { ContactPoint } from '@hl7fhir';
import { AddressViewModel } from '@hl7fhir/data-types';
import { PatientViewModel } from '@hl7fhir/resource-types';
import { joinValues } from '../section';

export class PatientDetailsSectionViewModel {
  constructor(readonly source: PatientViewModel) {}

  get fullAddress(): string | undefined {
    const addresses: (string | undefined)[] | undefined = this.source.addresses?.map((address: AddressViewModel) =>
      joinValues([address.street, address.houseNumber, address.postalCode, address.city, address.country]),
    );

    return joinValues(addresses, ', ');
  }

  get telecom(): string | undefined {
    const telecoms: (string | undefined)[] | undefined = this.source.resource?.telecom
      ?.filter((telecom: ContactPoint) => telecom.system !== 'email')
      .map((telecom: ContactPoint) => telecom.value);

    return joinValues(telecoms, ', ');
  }
}
