<app-section-header [headerConfig]="headerConfig"></app-section-header>

<div class="d-flex align-items-center mt-2">
  <div class="vr me-2 opacity-100 rounded align-self-stretch ms-2 flex-shrink-1"
       [ngStyle]="{ 'min-width': '5px', 'background-color': lineColor }"></div>
  <div class="flex-grow-1 section-font">

    @if(anyRecords) {
    <ng-content></ng-content>
    } @else {
    <app-no-records></app-no-records>
    }
  </div>
</div>