import { MedicationRequestViewModel } from '@hl7fhir/resource-types';
import { MenuItem, findMenuItemByIcon, getMenuConfig } from '@navigation/menu';
import { createSelector } from '@ngrx/store';
import { selectAllMedicationRequestViewModels } from '@store/hl7fhir';
import { Section } from '../section';
import { MedicationViewModel } from './medication.viewmodel';

export const selectMedicationsSection = createSelector(
  selectAllMedicationRequestViewModels,
  (viewModels: MedicationRequestViewModel[] | undefined) => {
    const menuItem: MenuItem | null = findMenuItemByIcon(getMenuConfig().menuItems, 'medication');

    return {
      lineColor: '#FBC7D7',
      headerConfig: {
        header: $localize`:@@app.ips.componentHeader.medications:Medications`,
        navigateTo: menuItem?.path,
        icon: menuItem?.icon,
      },
      records: viewModels?.map((viewModel: MedicationRequestViewModel) => new MedicationViewModel(viewModel)),
    } as Section<MedicationViewModel>;
  },
);
