import { ObservationViewModel } from '@hl7fhir/resource-types';
import { getCodingsDetails } from '../section';

export class ResultViewModel {
  get date(): string | undefined {
    return this.source.effective;
  }

  get codings(): string | undefined {
    return getCodingsDetails(this.source.resource?.code?.coding);
  }

  get outOfRange(): boolean | undefined {
    if (this.source.resource?.referenceRange && this.source.resource?.valueQuantity) {
      const value = this.source.resource.valueQuantity.value;
      const range = this.source.resource.referenceRange.length > 0 ? this.source.resource.referenceRange[0] : undefined;

      if (!range || !value) {
        return false;
      }

      const low = range.low?.value;
      const high = range.high?.value;

      return (low !== undefined && value <= low) || (high !== undefined && value >= high);
    }

    return false;
  }

  constructor(readonly source: ObservationViewModel) {}
}
