import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-record-divider',
  template: `<hr
    class="rounded w-25 my-2"
    [style.border-top]="'3.5px solid ' + color + ' !important'" />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordDividerComponent {
  @Input({ required: true }) color!: string;
}
