import { ProcedureViewModel } from '@hl7fhir/resource-types';
import { MenuItem, findMenuItemByIcon, getMenuConfig } from '@navigation/menu';
import { createSelector } from '@ngrx/store';
import { selectProcedureViewModels } from '@store/hl7fhir';
import { Section } from '../section';
import { ProcedureInfoViewModel } from './procedure.viewmodel';

export const selectProceduresSection = createSelector(
  selectProcedureViewModels,
  (viewModels: ProcedureViewModel[] | undefined) => {
    const menuItem: MenuItem | null = findMenuItemByIcon(getMenuConfig().menuItems, 'procedures');

    return {
      lineColor: '#448AFF',
      headerConfig: {
        header: $localize`:@@app.ips.componentHeader.procedures:Procedures`,
        navigateTo: menuItem?.path,
        icon: 'activity',
      },
      records: viewModels?.map((viewModel: ProcedureViewModel) => new ProcedureInfoViewModel(viewModel)),
    } as Section<ProcedureInfoViewModel>;
  },
);
