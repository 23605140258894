import { ImmunizationViewModel } from '@hl7fhir/resource-types';
import { MenuItem, findMenuItemByIcon, getMenuConfig } from '@navigation/menu';
import { createSelector } from '@ngrx/store';
import { selectAllImmunizationViewModels } from '@store/hl7fhir';
import { Section } from '../section';
import { VaccineViewModel } from './vaccine.viewmodel';

export const selectVaccinesSection = createSelector(
  selectAllImmunizationViewModels,
  (viewModels: ImmunizationViewModel[] | undefined) => {
    const menuItem: MenuItem | null = findMenuItemByIcon(getMenuConfig().menuItems, 'vaccinations');

    return {
      lineColor: '#FF7F49',
      headerConfig: {
        header: $localize`:@@app.ips.componentHeader.vaccines:Vaccines`,
        navigateTo: menuItem?.path,
        icon: menuItem?.icon,
      },
      records: viewModels?.map((viewModel: ImmunizationViewModel) => new VaccineViewModel(viewModel)),
    } as Section<VaccineViewModel>;
  },
);
