import { PatientContactViewModel } from '@hl7fhir/resource-types';
import { MenuItem, findMenuItemByIcon, getMenuConfig } from '@navigation/menu';
import { createSelector } from '@ngrx/store';
import { selectAllPatientContactViewModels } from '@store/hl7fhir';
import { Section } from '../section';
import { ContactViewModel } from './contact.viewmodel';

export const selectContactsSection = createSelector(
  selectAllPatientContactViewModels,
  (viewModels: PatientContactViewModel[]) => {
    const menuItem: MenuItem | null = findMenuItemByIcon(getMenuConfig().menuItems, 'contact-persons');

    return {
      lineColor: '#673AB7',
      headerConfig: {
        header: $localize`:@@app.ips.componentHeader.contacts:Contacts`,
        navigateTo: menuItem?.path,
        icon: menuItem?.icon,
      },
      records: viewModels?.map((resource: PatientContactViewModel) => new ContactViewModel(resource)),
    } as Section<ContactViewModel>;
  },
);
