import { formatLocaleDate } from '@globals';
import { ConditionViewModel } from '@hl7fhir/resource-types';
import { getCodingsDetails, joinValues } from '../section';

export class DiagnosisViewModel {
  constructor(readonly source: ConditionViewModel) {}

  get codings(): string | undefined {
    return getCodingsDetails(this.source.resource?.code?.coding);
  }

  get date(): string | undefined {
    return formatLocaleDate(this.source.onset);
  }

  get toString(): string | undefined {
    return joinValues([this.date, this.source.code, this.codings], ' - ');
  }
}
