import { formatDate } from '@angular/common';
import { AccountsResponse } from '@digi.me/models';
import { MenuItem, findMenuItemByIcon, getMenuConfig } from '@navigation/menu';
import { createSelector } from '@ngrx/store';
import { selectAccounts } from '@store/digi.me';
import { Section } from '../section';
import { SummaryViewModel } from './summary.viewmodel';

export const selectSummaryDetailsSection = createSelector(selectAccounts, (accounts: AccountsResponse[]) => {
  const menuItem: MenuItem | null = findMenuItemByIcon(getMenuConfig().menuItems, 'sources');

  return {
    lineColor: '#d1c4e9',
    headerConfig: {
      header: $localize`:@@app.ips.componentHeader.summaryDetails:Summary Details`,
      navigateTo: menuItem?.path,
      icon: menuItem?.icon,
    },
    records: accounts?.map((account: AccountsResponse) => new SummaryViewModel(account)),
    generatedDate: formatDate(Date.now(), 'long', $localize.locale!),
  } as Section<SummaryViewModel>;
});
