import { PatientViewModel } from '@hl7fhir/resource-types';
import { MenuItem, findMenuItemByIcon, getMenuConfig } from '@navigation/menu';
import { createSelector } from '@ngrx/store';
import { selectAllPatientViewModels } from '@store/hl7fhir';
import { Section } from '../section';
import { PatientDetailsSectionViewModel } from './patient-details.viewmodel';

export const selectPatientDetailsSection = createSelector(
  selectAllPatientViewModels,
  (viewModels: PatientViewModel[] | undefined) => {
    const menuItem: MenuItem | null = findMenuItemByIcon(getMenuConfig().menuItems, 'patient-details');

    return {
      lineColor: '#3F51B5',
      headerConfig: {
        header: $localize`:@@app.ips.componentHeader.patientDetails:Patient Details`,
        navigateTo: menuItem?.path,
        icon: menuItem?.icon,
      },
      records: viewModels?.map((viewModel: PatientViewModel) => new PatientDetailsSectionViewModel(viewModel)),
    } as Section<PatientDetailsSectionViewModel>;
  },
);
