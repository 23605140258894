import { getEmail, getPhone } from '@hl7fhir/data-types';
import { PatientContactViewModel } from '@hl7fhir/resource-types';
import { joinValues } from '../section';

export class ContactViewModel {
  constructor(readonly source: PatientContactViewModel) {}

  get fullAddress(): string | undefined {
    if (!this.source.address) {
      return undefined;
    }

    const addressParts = [
      this.source.address.street,
      this.source.address.houseNumber,
      this.source.address.city,
      this.source.address.postalCode,
      this.source.address.country,
    ];

    return joinValues(addressParts, ' ');
  }

  get phone(): string | undefined {
    return getPhone(this.source.element?.telecom);
  }

  get email(): string | undefined {
    return getEmail(this.source.element?.telecom);
  }

  get toString(): string | undefined {
    return joinValues([this.source.name, this.source.relationship, this.phone, this.email, this.fullAddress], ' - ');
  }
}
