import { AllergyIntoleranceViewModel } from '@hl7fhir/resource-types';
import { MenuItem, findMenuItemByIcon, getMenuConfig } from '@navigation/menu';
import { createSelector } from '@ngrx/store';
import { selectAllAllergyIntoleranceViewModels } from '@store/hl7fhir';
import { Badge, Section, findBadge } from '../section';
import { AllergyIntoleranceInfoViewModel } from './allergy-intolerance-info.viewmodel';

export const selectAllergiesIntolerancesSection = createSelector(
  selectAllAllergyIntoleranceViewModels,
  (viewModels: AllergyIntoleranceViewModel[] | undefined) => {
    const menuItem: MenuItem | null = findMenuItemByIcon(getMenuConfig().menuItems, 'allergy-intolerances');

    const vms: AllergyIntoleranceInfoViewModel[] | undefined = viewModels?.map(
      (viewModel: AllergyIntoleranceViewModel) => new AllergyIntoleranceInfoViewModel(viewModel),
    );

    return {
      lineColor: '#F7BC42',
      headerConfig: {
        header: $localize`:@@app.ips.componentHeader.allergies:Allergies and Intolerances`,
        navigateTo: menuItem?.path,
        icon: menuItem?.icon,
      },
      records: groupByCriticality(vms),
    } as Section<{
      badge: Badge;
      vms: AllergyIntoleranceInfoViewModel[];
    }>;
  },
);

function groupByCriticality(viewModels: AllergyIntoleranceInfoViewModel[] | undefined):
  | {
      badge: Badge;
      vms: AllergyIntoleranceInfoViewModel[];
    }[]
  | undefined {
  if (!viewModels) {
    return undefined;
  }

  const result: {
    badge: Badge;
    vms: AllergyIntoleranceInfoViewModel[];
  }[] = [];

  viewModels.forEach((viewModel: AllergyIntoleranceInfoViewModel) => {
    let group = result.find((group) => group.badge?.code && viewModel.severityLevel === group.badge.code);

    if (!group) {
      group = {
        badge: {
          ...findBadge(viewModel.severityLevel),
          label: viewModel.source.criticality ?? viewModel.source.resource?.criticality,
        },
        vms: [],
      };

      result.push(group);
    }

    group.vms.push(viewModel);
  });

  result.sort((a, b) => {
    const codeA = a.badge.code ?? 0;
    const codeB = b.badge.code ?? 0;
    if (codeA < codeB) {
      return -1;
    }
    if (codeA > codeB) {
      return 1;
    }
    return 0;
  });

  return result;
}
