import { ProcedureViewModel } from '@hl7fhir/resource-types';
import { getCodingsDetails, joinValues } from '../section';

export class ProcedureInfoViewModel {
  constructor(readonly source: ProcedureViewModel) {}

  get date(): string | undefined {
    return this.source.performed;
  }

  get codings(): string | undefined {
    return getCodingsDetails(this.source.resource?.code?.coding);
  }

  get toString(): string | undefined {
    return joinValues([this.date, this.source.code, this.source.bodySite, this.source.laterality, this.codings], ' - ');
  }
}
