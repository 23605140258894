import { formatDate } from '@angular/common';
import { AccountsResponse } from '@digi.me/models';

export class SummaryViewModel {
  constructor(readonly source: AccountsResponse) {}

  get lastSynced(): string | undefined {
    if (!this.source.updatedDate) {
      return undefined;
    }

    return formatDate(this.source.updatedDate, 'longDate', $localize.locale!);
  }
}
