import { ConditionViewModel } from '@hl7fhir/resource-types';
import { MenuItem, findMenuItemByIcon, getMenuConfig } from '@navigation/menu';
import { createSelector } from '@ngrx/store';
import { selectConditionViewModels } from '@store/hl7fhir';
import { Section } from '../section';
import { DiagnosisViewModel } from './diagnosis.viewmodel';

export const selectDiagnosesSection = createSelector(
  selectConditionViewModels,
  (viewModels: ConditionViewModel[] | undefined) => {
    const menuItem: MenuItem | null = findMenuItemByIcon(getMenuConfig().menuItems, 'diagnoses');

    return {
      lineColor: '#bdbdbd',
      headerConfig: {
        header: $localize`:@@app.ips.componentHeader.diagnoses:Diagnoses`,
        navigateTo: menuItem?.path,
        icon: menuItem?.icon,
      },
      records: viewModels?.map((viewModel: ConditionViewModel) => new DiagnosisViewModel(viewModel)),
    } as Section<DiagnosisViewModel>;
  },
);
