<div class="badge mb-2 text-wrap text-break text-start section-badge-font"
     [ngStyle]="{ 'background-color': badge!.color}">

  <div class="d-flex align-items-center">
    <svg *ngIf="badge!.icon"
         class="d-inline-block badge-icon"
         aria-hidden="true">
      <use [attr.xlink:href]="svgDefaultPath + '#' + badge!.icon"></use>
    </svg>

    <div [ngStyle]="{ 'color': badge!.textColor }">{{ badge!.label }}
      <ng-content></ng-content>
    </div>
  </div>
</div>