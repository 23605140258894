import { ImmunizationViewModel } from '@hl7fhir/resource-types';
import { getCodingsDetails, joinValues } from '../section';

export class VaccineViewModel {
  constructor(readonly source: ImmunizationViewModel) {}

  get date(): string | undefined {
    return this.source.occurrence;
  }

  get codings(): string | undefined {
    return getCodingsDetails(this.source.resource?.vaccineCode?.coding);
  }

  get toString(): string | undefined {
    return joinValues([this.date, this.source.vaccineCode, this.codings], ' - ');
  }
}
