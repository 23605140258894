import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HeaderConfig } from '@globals';
import { SharedModule } from '@shared';
import { NoRecordsComponent } from './no-records.component';
import { SectionHeaderComponent } from './section-header/section-header.component';

@Component({
  standalone: true,
  selector: 'app-section',
  templateUrl: 'section.component.html',
  styles: [
    `
      :host ::ng-deep .section-font * {
        font-family: 'PT Mono' !important;
      }
    `,
  ],
  imports: [SharedModule, SectionHeaderComponent, NoRecordsComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent {
  @Input({ required: true }) headerConfig!: HeaderConfig;
  @Input({ required: true }) lineColor!: string | undefined;
  @Input({ required: false }) anyRecords = false;
}
