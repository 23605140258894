import { CodeableConcept, Coding, Extension } from '@hl7fhir';
import { ExtensionPipe } from '@hl7fhir/extensibility';
import { AllergyIntoleranceViewModel } from '@hl7fhir/resource-types';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { SelectPipe } from '@shared';
import { getCodingsDetails, joinValues, SeverityLevel } from '../section';

const CRITICALITY_CODES = {
  FATAL: '24484000',
  SEVERE: '399166001',
  MILD: '255604002',
  MODERATE: '6736007',
};

export class AllergyIntoleranceInfoViewModel {
  get codings(): string | undefined {
    return getCodingsDetails(this.source.resource?.code?.coding);
  }

  get severityLevel(): SeverityLevel {
    const codes: string[] = [];

    if (this.source.resource?._criticality?.extension) {
      const extension: Extension | undefined = new ExtensionPipe().transform(
        this.source.resource?._criticality?.extension,
        StructureDefinition.Nictiz.CODE.specification,
      );

      if (extension) {
        codes.push(...(extractCodesFromExtensions(extension) ?? []));
      } else {
        codes.push(this.source.resource.criticality!);
      }
    }

    let highestPriority: SeverityLevel = SeverityLevel.Low;

    for (const code of codes) {
      let currentPriority: SeverityLevel;

      switch (code) {
        case CRITICALITY_CODES.FATAL:
        case 'high':
          currentPriority = SeverityLevel.Fatal;
          break;
        case CRITICALITY_CODES.SEVERE:
          currentPriority = SeverityLevel.Severe;
          break;
        case CRITICALITY_CODES.MILD:
        case CRITICALITY_CODES.MODERATE:
        case 'low':
        case 'unable-to-assess':
        default:
          currentPriority = SeverityLevel.Low;
          break;
      }

      if (currentPriority < highestPriority) {
        highestPriority = currentPriority;
      }

      return highestPriority;
    }

    return SeverityLevel.Low;
  }

  get toString(): string | undefined {
    return joinValues([this.source.code, this.codings]);
  }

  constructor(readonly source: AllergyIntoleranceViewModel) {}
}

function extractCodesFromExtensions(extension: Extension): string[] | undefined {
  const codeableConcept: CodeableConcept | undefined = new SelectPipe().transform(extension, 'valueCodeableConcept');

  return codeableConcept?.coding
    ?.map((coding: Coding | undefined) => coding?.code)
    .filter((code: string | undefined): code is string => code !== undefined);
}
