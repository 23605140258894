import { HeaderConfig } from '@globals';

export enum SeverityLevel {
  Fatal = 1,
  Severe = 2,
  Low = 3,
}

export interface Section<T> {
  headerConfig: HeaderConfig;
  records: T[] | undefined;
  lineColor: string;
  generatedDate?: string;
}

export class Badge {
  label?: string;
  code?: SeverityLevel;
  color?: string;
  icon?: string;
  textColor?: string;
}
