import { ObservationViewModel } from '@hl7fhir/resource-types';
import { MenuItem, findMenuItemByIcon, getMenuConfig } from '@navigation/menu';
import { createSelector } from '@ngrx/store';
import { selectAllMedMijLaboratoryViewModels } from '@store/hl7fhir';
import { Section } from '../section';
import { ResultViewModel } from './result.viewmodel';

export const selectResultsSection = createSelector(
  selectAllMedMijLaboratoryViewModels,
  (viewModels: ObservationViewModel[] | undefined) => {
    const menuItem: MenuItem | null = findMenuItemByIcon(getMenuConfig().menuItems, 'results');

    return {
      lineColor: '#80CBC4',
      headerConfig: {
        header: $localize`:@@app.ips.componentHeader.results:Results`,
        navigateTo: menuItem?.path,
        icon: menuItem?.icon,
      },
      records: viewModels
        ?.map((viewModel: ObservationViewModel) => new ResultViewModel(viewModel))
        .filter((viewModel: ResultViewModel) => !viewModel.outOfRange),
    } as Section<ResultViewModel>;
  },
);
